
@mixin f-style($size, $weight, $color){
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

nav{
    display: flex;
    align-items: center;
    z-index: 5;
    height: 70px;
    width: 100%;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--primary-color);
    background-color: var(--primary-full-color);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.shadow-nav{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nav-wrapper{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
}

.nav__authour-title{
    h1{
        cursor: pointer !important;
        @include f-style(28px, 600, var(--light-color));
    }
}

.nav__menu{
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    list-style-type: none;
    .item-link{
        transition: .2s;
        letter-spacing: .7px;
        text-decoration: none;
        padding: 5px 8px;
        border-radius: 4px;
        @include f-style(16px, 400, var(--text-light));
        &:hover{
            cursor: pointer;
            background-color: var(--primary-color);
        }
    }
}

.responsive__menu-wrapper{
    position: relative absolute;
    left: 0;
    top: 0%;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    display: none;

}

.menu-btn{
    display: none;
    color: var(--light-color);
    font-size: 30px;
}

@media only screen and (max-width:866px){
    .nav__menu{
    column-gap: 1rem;
        .item-link{
        @include f-style(15px, 400, var(--text-light));

        }
    }
}
@media only screen and (max-width: 758px){
    nav{
        position: relative;
        height: 60px;
    }
    .nav__authour-title{
        h1{
            font-size: 28px;
        }
    }
  
 
    .menu-btn{
        display: block;
    }

    .nav__menu{
        display: none;
        left: 0;
        width: 100%;
        border-top: 2px solid #1a191998;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.5rem;
        background-color:var(--primary-full-color);
        padding: 1.8rem 1.5rem 1.6rem 1.5rem !important;
        position: absolute;
            top: calc(100% + 30px);
            li{
                display: flex;
                align-items: center;
                width: 100% !important;
                .item-link{
                    font-size: 19px;
                    padding: 10px ;
                    width: 100% !important;
                }
            }

    }
}


@media only screen and (max-width:700px){
    .nav__menu{
        display: none;
        
    }
    .menu-btn{
        display: block;
    }   
}



@media only screen and (max-width: 430px){
    .nav__authour-title{
        h1{
            font-size: 24px;
            letter-spacing: 0;
        }
    }
    .nav__menu{
        padding-top: 1rem;
        .item-link{
            font-size: 17px;
        }
    }
}
@media only screen and (max-width: 320px){
    .nav__authour-title{
        h1{
            font-size: 20px;
            letter-spacing: .5px;
        }
    }
    .nav__menu{
        padding-top: 1rem;
        .item-link{
            font-size: 15px;
        }
    }

}