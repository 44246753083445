@mixin f-style($size, $weight, $color) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}


.about {
    margin-top: 3rem;
    padding: 8rem 0;
    background-color: #030a1d;
}



.about-wrapper {
    width: 100%;
}

.about-header {
    width: 100%;
    text-align: center;

    h2 {
        @include f-style(35px, 500, var(--primary-shadow-color));
        letter-spacing: 3px;
    }

    .header-line {
        border-radius: 5px;
        height: 5px;
        width: 3rem;
        background-color: white;
        margin: 1.2rem auto 0;
    }

    .header-text {
        margin-top: .3rem;
        @include f-style(18px, 400, var(--light-color));
        max-width: 880px;
        margin: 1rem auto 0;
        line-height: 1.7;
    }
}

.about__content-wrapper {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem;

    .about__content {
        max-width: 490px;
        width: 100%;

        h4 {
            @include f-style(28px, 600, var(--light-color));
        }

        p {
            margin-top: 1rem;
            @include f-style(15px, 400, rgb(182, 179, 179));
            line-height: 26px;
        }

        .contact-link {
            padding: 1rem 3rem;
            font-size: 1.6rem;
            margin-top: 3rem;
            @include f-style(15px, 500, var(--light-color));
            display: inline-block;
            background-color: #3061B6;
            border-radius: 5px;
            text-decoration: none;
        }
    }

    .skills__content {
        h4 {
            @include f-style(28px, 600, var(--light-color));
        }

        .skills {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            p {
                text-align: center;
                @include f-style(15px, 500, rgb(224, 221, 221));
                line-height: 26px;
                padding: 6px 14px;
                background-color: #3061B6;
                border-radius: 5px;
            }
        }
    }
}


@media only screen and (max-width:920px) {
    .about-header {
        width: 100%;
        text-align: center;
        padding: 0 2rem;

        h2 {
            @include f-style(35px, 500, var(--primary-shadow-color));
        }

        .header-line {
            border-radius: 5px;
            height: 5px;
            width: 3rem;
        }

        .header-text {
            @include f-style(16px, 400, var(--light-color));
        }
    }

    .about__content-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 4rem;

        .about__content {
            max-width: 660px;

        }
    }
}

@media only screen and (max-width:680px) {
    .about-header {
        padding: 0 1rem;

        h2 {
            @include f-style(30px, 500, var(--primary-shadow-color));
        }

        .header-line {
            border-radius: 5px;
            height: 4px;
            width: 3rem;
        }

        .header-text {
            @include f-style(15px, 400, var(--light-color));
        }
    }

    .about__content-wrapper {
        .about__content {
            max-width: 660px;
            p{
                margin-top: 1rem;
                @include f-style(14px, 400, rgb(182, 179, 179));
                line-height: 26px;
            }
        }
        .skills__content {
            h4 {
                @include f-style(28px, 600, var(--light-color));
            }
    
            .skills {
    
                p {
                    @include f-style(14px, 450, rgb(224, 221, 221));
                    line-height: 26px;
                    padding: 5px 12px;
                }
            }
        }
    }

}


@media only screen and (max-width:480px) {
    .about-header {
        padding: 0 1rem;

        h2 {
            @include f-style(30px, 500, var(--primary-shadow-color));
        }

        .header-line {
            height: 3px;
            width: 3rem;
        }

        .header-text {
            @include f-style(13px, 400, var(--light-color));
        }
    }

    .about__content-wrapper {
        .about__content {
            max-width: 660px;
            p{
                margin-top: 1.2rem;
                @include f-style(14px, 400, rgb(182, 179, 179));
                line-height: 24px;
            }
            .contact-link {
                padding: .9rem 2.6rem;
                font-size: 1.6rem;
                margin-top: 2rem;
                @include f-style(15px, 500, var(--light-color));
            }
        }
        .skills__content {
            h4 {
                @include f-style(28px, 600, var(--light-color));
            }
    
            .skills {
                gap: 13px;
                p {
                    @include f-style(14px, 400, rgb(224, 221, 221));
                    line-height: 23px;
                    padding: 5px 10px;
                }
            }
        }
    }

}