.skills{
    // background-color: var(--bg-secondary-color);
    padding: 1.5rem 0 2.8rem 0;
    // margin-top: 2rem;
}
.skills-wrapper{
    width: 100%;

}
.skill-title{
    font-size: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    color: var(--primary-shadow-color);
    text-shadow:  0 0 4px var(--primary-color);
}

.skill__items-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    row-gap: 1.9rem;
    width: 100%;
    margin: 3rem  auto 0;
}
.skill-item{
    text-align: center;
    img{
        width: 55px;
        height: 60px;
        object-fit: contain;
        border-radius: 6px;
        background: transparent;
    }
    p{
        color: var(--light-color);
        font-weight: 500;
        font-size: 19px;
        opacity: 0.6;
        margin-top: 5px;
    }
}

// RESPONSIVE STYLES

@media only screen and (max-width:878px){
    .skill__items-wrapper{
        grid-template-columns: repeat(4, 1fr);
    }
}
@media only screen and (max-width:700px){
    .skill__items-wrapper{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width:478px){
    .skill__items-wrapper{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width:363px){
    .skill-item{
        img{
            width: 45px;
            height: 50px;
        }
        p{
            font-size: 17px;
        }
    }
}