*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  transition: .1s ease;
}


:root{
  --light-color: #fff;
  --text-light: rgb(248, 250, 252);
  --dark-color: #000;
  --secondary-color: #242B40;
  --bg-secondary-color: #252C41;
  --primary-full-color: #020817;
  --primary-color: #0a263d;
  --primary-shadow-color: #59B2F4;
}
body{
  background-color: var(--primary-full-color);
 
}

input::-webkit-inner-spin-button{
  appearance: none;
}

a{
  text-decoration: none;
}


.ip-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  h2{
    color: #fff;
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.ip-list{
  margin-top: 1rem;
  list-style: none;
  li{
    margin-top: 10px;
    color: #fff;
  }
}