@mixin f-style($size, $weight, $color){
    font-size: $size;
    font-weight: $weight;
    color: $color;
}
.contact{
    background-color: #030A1D;
    padding: 4rem 0 3rem ;
    margin: 6rem 0 0;
}
.contact-wrapper{
    width: 100%;
    text-align: center;

}

.contact-title{
    color: var(--light-color);
    font-size: 40px;
    font-weight: 600;
    display: inline-block;
    letter-spacing: .5px;
    color: #59B2F4;
    
}
.contact__actions-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    column-gap: 4rem;
    width: 100%;
    margin-top: 4rem;
}

.contact-details{
    text-align: left;
    width: 100%;
    max-width: 500px;
padding-bottom: 3.5rem;
    h3{
        @include f-style(48px, 600, rgb(248, 250, 252));
    }
    p{
        @include f-style(16px, 400, rgb(148, 163, 184));
        max-width: 370px;
        line-height: 26px;
        text-align: left;
        margin: 1rem 0 1.4rem;
    }
    .detail-item{
        display: flex;
        align-items: center !important;
        width: 100% !important;
        border: 2px solid #264e92a2;
        border-radius: 6px;
        padding: .6rem 1rem;
        margin-bottom: 1.4rem;
        &:hover{
            border: 2px solid #3371D9;
            background-color: #0a1b38;
        }
        i{
            margin-top: 3px;
            color: #3371D9;
            font-size: 20px;
        }
        p{
            margin: 0 .6rem;
            @include f-style(15px, 500, rgb(148, 163, 184));
        }
    }
}

.contact-form{
    max-width: 530px !important;
    width: 100% !important;
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    input{
        width: 100%;
        height: 42px;
        outline: none;
        border: 1px solid #264e92;
        background: transparent;
        text-indent: 15px;
        border-radius: 6px;
        @include f-style(15px, 500,  rgb(248, 250, 252));
        &:focus{
            box-shadow: 0 0 3px 1px #3371D9;
        }
    }
    textarea{
        width: 100%;
        outline: none;
        border: 1px solid #264e92;
        background: transparent;
        text-indent: 15px;
        border-radius: 6px;
        height: 100px;
        padding: .7rem .3rem;
        resize: none;
        @include f-style(15px, 500,  rgb(248, 250, 252));
        &:focus{
            box-shadow: 0 0 3px 1px #3371D9;
        }
    }
    button{
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        @include f-style(17px, 500,  #000);
        background-color: #eee;
        border: none;
        border-radius: 6px;
        padding: 10px 30px;
        &:hover{
            background-color: #c5c6c9;
            cursor: pointer;
        }
        i{
            font-size: 18px;
            margin-top: 4px;
        }
    }
}
.submitted-btn{
    opacity: .8 !important;
    cursor: not-allowed !important;
}


/* HTML: <div class="loader"></div> */
.loader {
    width: 25px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #252525;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}


//   RESPONSIVE STYLES

@media screen and (max-width: 958px) {
    .contact-details{
        max-width: 360px;
    padding-bottom: 3.5rem;
      
        p{
            @include f-style(16px, 400, rgb(148, 163, 184));
            max-width: 350px;
            line-height: 26px;
            margin: 1rem 0 1.4rem;
        }
        .detail-item{
       
            border: 2px solid #264e92a2;
            border-radius: 6px;
            padding: .6rem 1rem;
            margin-bottom: 1.4rem;
          
            i{
                margin-top: 3px;
                
                font-size: 18px;
            }
            p{
                margin: 0 .6rem;
                @include f-style(15px, 400, rgb(148, 163, 184));
            }
        }
    }
}


@media screen and (max-width: 870px) {
    .contact-details{
        max-width: 320px;
    padding-bottom: 3.5rem;
      
        p{
            @include f-style(14px, 400, rgb(148, 163, 184));
            max-width: 370px;
            line-height: 24px;
            margin: 1rem 0 1.4rem;
        }
        .detail-item{
       
            border: 2px solid #264e92a2;
            border-radius: 6px;
            padding: .5rem .8rem;
            margin-bottom: 1.4rem;
          
            i{
                margin-top: 3px;
                
                font-size: 18px;
            }
            p{
                margin: 0 .6rem;
                @include f-style(14px, 400, rgb(148, 163, 184));
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .contact__actions-wrapper{
        flex-direction: column;
        row-gap: 2rem;
        padding: 0 3.5rem;
    }
    .contact-details{
        max-width: 100%;
    padding-bottom: 2rem;
    border-bottom: 1px dashed #2363d1;
      
        p{
            @include f-style(16px, 400, rgb(148, 163, 184));
            max-width: 100%;
            line-height: 24px;
            margin: 1rem 0 1.4rem;
        }
        .detail-item{
       
            border: 2px solid #264e92a2;
            border-radius: 6px;
            padding: .6rem .8rem;
            margin-bottom: 1.4rem;
          
            i{
                margin-top: 3px;
                
                font-size: 20px;
            }
            p{
                margin: 0 .6rem;
                @include f-style(15px, 500, rgb(148, 163, 184));
            }
        }
    }
    .contact-form{
        max-width: 100% !important;
        display: flex;
        row-gap: 1rem;
        
    }
}


@media screen and (max-width: 564px) {
    .contact__actions-wrapper{
        padding: 0 2rem;
    }

    .contact-details{
    padding-bottom: 2rem;
      
        p{
            @include f-style(15px, 400, rgb(148, 163, 184));
            line-height: 24px;
            margin: 1rem 0 1.4rem;
        }
        .detail-item{
            padding: .5rem .8rem;
            margin-bottom: 1.4rem;
          
            i{
                margin-top: 3px;
                
                font-size: 20px;
            }
            p{
                margin: 0 .6rem;
                @include f-style(15px, 400, rgb(148, 163, 184));
            }
        }
    }
    .contact-form{
        max-width: 100% !important;
        display: flex;
        row-gap: 1rem;
        input{
            height: 42px;
            text-indent: 12px;
            @include f-style(15px, 400,  rgb(248, 250, 252));
          
        }
        textarea{
            width: 100%;
            outline: none;
            text-indent: 15px;
            height: 100px;
            padding: .7rem .3rem;
            @include f-style(15px, 500,  rgb(248, 250, 252));
        }
        button{
            width: 100%;
          
        }
    }
}


@media only screen and (max-width: 430px){
    .contact-title{
        font-size: 32px;
        font-weight: 500;
        letter-spacing: .5px;
        color: #59B2F4;
        
    }
    .contact__actions-wrapper{
        padding: 0 0rem;
    }
    .contact-details{
        padding-bottom: 2rem;
          
            p{
                @include f-style(14px, 400, rgb(148, 163, 184));
                line-height: 24px;
                margin: 1rem 0 1.4rem;
            }
            .detail-item{
                padding: .5rem .8rem;
                margin-bottom: 1.4rem;
              
                i{
                    margin-top: 3px;
                    
                    font-size: 20px;
                }
                p{
                    margin: 0 .6rem;
                    @include f-style(14px, 400, rgb(148, 163, 184));
                }
            }
            
        }
        .contact-form{
            button{
                margin-top: 1rem;
                @include f-style(16px, 500,  #000);
                padding: 8px 30px;
                i{
                    font-size: 17px;
                    margin-top: 5px;
                }
            }
        }
}