.projects {
    // background-color: #030A1D;
    width: 100%;
    margin-top: 5rem;
    padding: 3rem 0;
}

.portfolio-title {
    font-size: 44px;
    letter-spacing: 1px;
    text-align: center;
    color: var(--light-color);
    color: #59B2F4;

}

.projects__card-wrapper{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    gap: 1.8rem 2rem;
}

.project-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 370px;
    width: 100%;
    border-radius: 8px;
    padding: 2rem 1rem;
    background-color: #111827;
    transition: .3s ease-in !important;
    	&:hover{
            transform: translateY(-2%) !important;
        }
    .title {
        font-size: 20px;
        color: var(--light-color);
        font-weight: 600;
    }

    img {
        width: 100%;
        height: 175px;
        object-fit: contain;
    }

    .description {
        color: rgb(100, 116, 139);
        font-size: 16px;
        font-weight: 400;
        margin: 10px 0;
        line-height: 24px;
    }

    .skills-title {
        color: rgb(148, 163, 184);
        font-size: 18px;
        font-weight: 400;
        margin-top: 1rem;
    }
    .project-skills{
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-items: flex-start;
        align-items: start !important;
        gap: 12px 10px;
        p{
            border: 1px solid var(--secondary-color);
            color: var(--light-color);
            padding: 4px 8px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .card-btns{
        width: 100%;
        height: 70px;
        padding: 0 .3rem;
        margin-top: 3rem;
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .btn{
            display: flex;
            align-items: center;
            column-gap: 3px;
            font-size: 15px;
            font-weight: 500;
            padding: 8px 12px;
            border-radius: 6px;
        }
        .demo-link{
            color: rgb(209, 213, 219);
            border: 1px solid rgb(209, 213, 219);
        }
        .detail-link{
            display: none;
            background-color: var(--light-color);
            color: rgb(15, 23, 42);
        }
    }
}


// RESPONSIVE STYLES

@media screen and (max-width: 940px) {
    .portfolio-title{
    font-size: 38px;
    }
    .projects__card-wrapper{
        grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem 1.5rem;

    }
    .project-card{
        max-width: 100%;
        img{
        height: 220px;
        }
        .skills-title{
        margin-top: .3rem;

        }
        .card-btns{
        margin-top: 2rem;

        }
        
    }
}
@media screen and (max-width: 786px) {
    .project-card{
        .description {
            font-size: 15px;
            margin: 10px 0;
            line-height: 22px;
        }
        .project-skills{
            p{
                padding: 4px 8px;
                border-radius: 6px;
                font-size: 13px;
            font-weight: 500;

            }
        }
       
  }
}

@media screen and (max-width: 650px) {
    .portfolio-title{
        font-size: 35px;
        }
    .project-card{
    padding: 1.6rem .8rem;
    .title {
        font-size: 18px;
    }
        .description {
            font-size: 13px;
            margin: 7px 0;
            line-height: 22px;
        }
        img{
            height: 200px;
            }
            .skills-title{
            margin-top: .3rem;
    
            }
            .card-btns{
            margin-top: 2rem;
    
            }
        .project-skills{
            p{
                padding: 4px 6px;
                font-size: 12px;
            }
        }
        .card-btns{
            .btn{
                font-size: 14px;
                font-weight: 500;
                padding: 7px 10px;
            }
        }
       
  }
}


@media only screen and (max-width:616px){
    .projects__card-wrapper{
        grid-template-columns: repeat(1, 1fr);
    }
    .project-card{
        padding: 1.6rem .8rem;
        .title {
            font-size: 24px;
        }
            .description {
                font-size: 14px;
                margin: 7px 0;
                line-height: 22px;
            }
        img{
            width: 100%;
            height: 230px;
            object-fit: cover;
            margin: 1rem 0;
            object-position: top;
        }
    .skills-title{
    margin-top: .3rem;
        font-size: 16px;

    }
    .card-btns{
    margin-top: 2rem;

    }
.project-skills{
    margin-top: 1rem;
    p{
        padding: 5px 8px;
        font-size: 14px;
    }
}
.card-btns{
    .btn{
        font-size: 16px;
        padding: 8px 12px;
    }

    }
}
}


@media only screen and (max-width:450px){
    .portfolio-title{
        font-size: 30px;
        }
    .project-card{
        padding: 1.6rem .8rem;
        .title {
            font-size: 20px;
        }
            .description {
                font-size: 13px;
                margin: 7px 0;
                line-height: 22px;
            }
        img{
            width: 100%;
            height: 200px;
            object-fit: cover;
            margin: 1rem 0;
            object-position: top;
        }
    .skills-title{
    margin-top: .3rem;
        font-size: 16px;

    }
    .card-btns{
    margin-top: 2rem;

    }
.project-skills{
    margin-top: 1rem;
    p{
        padding: 5px 8px;
        font-size: 13px;
    }
}
.card-btns{
    .btn{
        font-size: 15px;
        padding: 7px 11px;
    }

    }
}
}


@media only screen and (max-width:350px){
    .portfolio-title{
        font-size: 28px;
        }
    .project-card{
        padding: 1.2rem .8rem;
        .title {
            font-size: 18px;
        }
            .description {
                font-size: 12px;
                margin: 7px 0;
                line-height: 20px;
            }
        img{
            width: 100%;
            height: 155px;
            object-fit: contain;
            margin: 1rem 0;
            object-position: top;
        }
    .skills-title{
    margin-top: .3rem;
        font-size: 16px;

    }
    .card-btns{
    margin-top: 2rem;

    }
.project-skills{
    margin-top: .6rem;
    p{
        padding: 5px 8px;
        font-size: 11px;
    }
}
.card-btns{
    .btn{
        font-size: 13px;
        padding: 7px 11px;
    }

    }
}
}