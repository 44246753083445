.ip-content {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
      color: #333;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
  
      thead {
        background-color: #007bff;
        color: #fff;
      }
  
      th, td {
        padding: 12px 15px;
        text-align: left;
        border: 1px solid #ddd;
      }
  
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
  
      tbody tr:hover {
        background-color: #f1f1f1;
      }
    }
  
    @media screen and (max-width: 768px) {
      table, thead, tbody, th, td, tr {
        display: block;
      }
  
      thead {
        display: none;
      }
  
      tbody tr {
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
      }
  
      td {
        padding: 10px;
        text-align: right;
        position: relative;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        &:before {
          content: attr(data-label);
          position: absolute;
          left: 0;
          width: 50%;
          font-weight: bold;
          text-align: left;
          padding-left: 10px;
          color: #333;
        }
      }
    }
  }
  