.hero {
    width: 100%;
    margin-top: 3rem !important;
    padding: 7rem 1rem;
}

.hero-wrapper {
    width: 100%;
}

.hero-content {
    text-align: center !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h5 {
        font-size: 56px;
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: var(--light-color);  
    }

    .typewriter-text {
        margin-top: 1.2rem;
        color: rgb(59, 130, 246);
        font-size: 36px;
        text-align: center;
        display: none;
        font-weight: 600;
    }

    p {
        margin-top: 2.6rem;
        font-size: 20px;
        font-weight: 400;
        color: var(--text-light);
        max-width: 755px !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.6;
    }

    .resume {
        display: inline-block;
        margin-top: 2rem !important;
        padding: 14px 15px;
        border-radius: 6px;
        font-size: 16px;
        color: rgb(59, 130, 246);
        border: 2px solid rgb(59, 130, 246);

        &:hover {
            background-color: rgba(59, 131, 246, 0.158);
        }
    }
}


.hero-socials {
    display: none;
    margin-top: 2.5rem;

    .socials {
        margin-top: 13px;
        display: flex;
        align-items: center;
        column-gap: 1rem;

        .social-link {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--light-color);
            font-size: 24px;
            border: 1px solid var(--primary-shadow-color);
            padding: 8px;
            border-radius: 50%;

            &:hover {
                background-color: var(--primary-shadow-color);
                color: var(--light-color);
                transform: scale(1.15);
            }
        }
    }
}



// Responsive Styles
@media only screen and (max-width: 1050px) {
    .hero-content {
        h5 {
            font-size: 50px;
            font-weight: 700;
            letter-spacing: 3px;
        }
    }
}


@media only screen and (max-width: 880px) {
    .hero{
    padding: 5rem 1rem;

    }
    .hero-content {
        h5 {
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 3px;
        }
    
        p {
            margin-top: 2rem;
            font-size: 18px;
            font-weight: 400;
            max-width: 755px !important;
        
            line-height: 1.6;
        }
    
        .resume {
            margin-top: 2rem !important;
            padding: 14px 15px;
            font-size: 16px;
        }
    }
}


@media only screen and (max-width: 520px) {
    .hero{
    padding: 5rem 1rem;

    }
    .hero-content {
        h5 {
            font-size: 35px;
            font-weight: 650;
            letter-spacing: 2.5px;
            line-height: 3.2rem;
        }
    
        p {
            margin-top: 1.6rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
        }
    
        .resume {
            margin-top: 2rem !important;
            padding: 11px 12px;
            font-size: 15px;
        }
    }
}


@media only screen and (max-width: 380px) {
    .hero{
    padding: 4rem 1rem;

    }
    .hero-content {
        h5 {
            font-size: 32px;
            font-weight: 650;
            letter-spacing: 2.5px;
            line-height: 3.2rem;
        }
    
        p {
            margin-top: 1.6rem;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 1px;
        }
    
        .resume {
            margin-top: 2rem !important;
            padding: 11px 12px;
            font-size: 14px;
        }
    }
}